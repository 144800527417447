#hero {
  width: 100%;
  height: 100vh;
  background: url("./hero_bg2-min.jpg") top right no-repeat;
  background-size: cover;
  position: relative;

  &:before {
    content: "";
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  .container {
    z-index: 1;

    h1 {
      margin: 0;
      font-size: 64px;
      font-weight: 700;
      line-height: 56px;
      color: #45505b;
    }

    .social-links {
      margin-top: 30px;

      a {
        font-size: 24px;
        display: inline-block;
        color: #45505b;
        line-height: 1;
        margin-right: 20px;
        transition: 0.3s;

        & :hover {
          color: #0563bb;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  #hero {
    padding-left: 160px;
  }
}

@media (max-width: 992px) {
  #hero {
    text-align: center;

    h1 {
      font-size: 32px;
      line-height: 36px;
    }

    p {
      margin-top: 10px;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
