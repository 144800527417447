.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #0563bb;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;

  &:hover {
    background: #0678e3;
    color: #fff;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
}
