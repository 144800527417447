/**
* Desktop Navigation
*/
.nav-menu {
  padding: 0;
  display: block;

  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul li {
    position: relative;
    white-space: nowrap;
  }

  a,
  a:focus {
    display: flex;
    align-items: center;
    color: #45505b;
    padding: 10px 18px;
    margin-bottom: 8px;
    transition: 0.3s;
    font-size: 15px;
    border-radius: 50px;
    background: #f2f3f5;
    height: 56px;
    width: 100%;
    overflow: hidden;
    i {
      font-size: 20px;
    }
    span {
      padding: 0 5px 0 7px;
      color: #45505b;
    }
  }
}

@media (min-width: 992px) {
  .nav-menu {
    a,
    a:focus {
      width: 56px;
      span {
        display: none;
        color: #fff;
      }
    }
  }
}

.nav-menu a:hover,
.nav-menu .active,
.nav-menu .active:focus,
.nav-menu li:hover > a {
  color: #fff;
  background: #0563bb;
}

.nav-menu a:hover span, .nav-menu .active span, .nav-menu .active:focus span, .nav-menu li:hover > a span {
  color: #fff;
}

.nav-menu a:hover, .nav-menu li:hover > a {
  width: 100%;
  color: #fff;
}

.nav-menu a:hover span, .nav-menu li:hover > a span {
  display: block;
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 28px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 0;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px;
}

.mobile-nav-toggle i {
  color: #45505b;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active #header {
  left: 0;
}

.mobile-nav-active .mobile-nav-toggle {
  color: #fff;
  background-color: #0563bb;
}
